<template>
  <div style="width: 100% ;padding: 10px">
    <el-row>
      <el-col :span="3">
        关键词:
      </el-col>
      <el-col :span="5">
        <el-input v-model="search"
                  placeholder="请输入关键词"
                  clearable style="width : 77%"></el-input>
      </el-col>
      <el-col :span="3">
        创建时间:
      </el-col>
      <el-col :span="13">
        <el-date-picker
            v-model="timeSpase"
            type="datetimerange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            :picker-options="pickerOptions">
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        往来单位:
      </el-col>
      <el-col :span="5">
        <el-select v-model="unitSelect" multiple placeholder="请选择往来单位">
          <el-option
              v-for="item in unitList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        过账时间:
      </el-col>
      <el-col :span="13">
        <el-date-picker
            v-model="timeSpase2"
            type="datetimerange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            :picker-options="pickerOptions">
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        交易账户:
      </el-col>
      <el-col :span="5">
        <el-select v-model="accountSelect" multiple placeholder="请选择交易账户">
          <el-option
              v-for="item in accountList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        单据类型:
      </el-col>
      <el-col :span="5">
        <el-select v-model="typeSelect" multiple placeholder="请选择单据类型">
          <el-option
              v-for="item in typeList"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        过账状态:
      </el-col>
      <el-col :span="5">
        <el-select v-model="finishedSelect" clearable placeholder="请选择单据状态">
          <el-option
              v-for="item in finishedList"
              :label="item.name"
              :value="item.type">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :span="2">
      <el-button @click="select">
        筛选
      </el-button>
      <el-button @click="orderCreateShow = true,formOrderList = [],orderForm={}">
        单据创建
      </el-button>
      <el-button @click="accountShow = true">
        交易账户编辑
      </el-button>
      <el-button @click="unitShow = true">
        往来单位编辑
      </el-button>
      <el-button @click="exportExcel()">
        导出Excel文件
      </el-button>
<!--      <el-button @click="exportOrder()">-->
<!--        导出Order文件-->
<!--      </el-button>-->
    </el-row>
    <el-row>
      <el-table
          :data="tableDate"
          border
          show-summary
          :summary-method="getSummaries"
          :row-style="changRed"
          style="width: 100%">
        <el-table-column type="expand"
                         label="展开详情"
                         fixed="left"
                         width="120">
          <template slot-scope="props">
            <div v-if="item !== ''" v-for="item in props.row.orderList.split(',')" style="padding-left: 20px">
              <span>{{item.split(":")[0]}}</span>:
              <span>{{getEntityNameById(item.split(":")[1])}}</span>
              ,数量:<span>{{item.split(":")[2]}}</span>件
              ,价格:<span>{{item.split(":")[3]}}</span>元
            </div>
          </template>
        </el-table-column>
        <el-table-column
            v-if="false"
            fixed="left"
            label="id"
            prop="id"
            width="100">
        </el-table-column>
        <el-table-column
            fixed="left"
            label="单据名称"
            prop="name"
            width="130">
        </el-table-column>
        <el-table-column
            fixed="left"
            label="单据编号"
            prop="code"
            width="130">
        </el-table-column>
        <el-table-column
            label="摘要"
            prop="abs"
            width="200">
        </el-table-column>
        <el-table-column
            label="经手人"
            prop="handlingPerson"
            width="100">
          <template slot-scope="scope">
            <span>{{getPersonName(scope.row.handlingPerson)}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="附加信息"
            prop="abs"
            width="130">
        </el-table-column>
        <el-table-column
            label="往来单位"
            prop="unit"
            width="130">
          <template slot-scope="scope">
            {{getUnitName(scope.row.unit)}}
          </template>
        </el-table-column>
        <el-table-column
            label="金额"
            prop="money"
            width="130">
        </el-table-column>
        <el-table-column
            label="交易账户"
            prop="account"
            width="130">
          <template slot-scope="scope">
            {{getAccountName(scope.row.account)}}
          </template>
        </el-table-column>
        <el-table-column
            label="完成金额"
            prop="transactionAmount"
            width="130">
        </el-table-column>
        <el-table-column
            label="单据类型"
            prop="type"
            width="130">
          <template slot-scope="scope">
            {{getTypeName(scope.row.type)}}
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            prop="createTime"
            width="130">
          <template slot-scope="scope">
            {{DateChange(scope.row.createTime)}}
          </template>
        </el-table-column>
        <el-table-column
            label="过账人"
            prop="finishUser"
            width="130">
        </el-table-column>
        <el-table-column
            label="过账时间"
            prop="finishTime"
            width="130">
          <template slot-scope="scope">
            {{scope.row.finishTime ? DateChange(scope.row.finishTime) : ""}}
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="130">
          <template slot-scope="scope">
            <el-button v-if="scope.row.ret == '0'&&scope.row.finished == '0'" size="small" type="text" @click="OrderUpdate(scope.row)">编辑</el-button>
            <span v-if="scope.row.ret == '0'&&scope.row.finished == '1'">已过账</span>
            <el-button v-if="scope.row.ret == '0'&&scope.row.finished == '1'" size="small" type="text" @click="OrderReturn(scope.row)">红冲</el-button>
            <el-button v-if="scope.row.ret != '1'" size="small" type="text" @click="exportOrder(scope.row.id)">生成出库单</el-button>
            <span v-if="scope.row.ret == '1'">已红冲</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 10px 0">
        <el-pagination
            :current-page="currentPage4"
            :page-size="size"
            :page-sizes="[5, 10, 20, 50 ,100 , 500, -1]"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
        <div>
          <span style="padding-left: 30px">
            本页非红冲合计金额:{{inputMoneyToTal}}元;
          </span>
          <span style="padding-left: 30px">
            本页非红冲完成金额:{{inputMoneyFinish}}元;
          </span>
          <span style="padding-left: 30px">
            余额:{{inputMoney}}元;
          </span>
        </div>
      </div>
    </el-row>
    <!--  单据创建  -->
    <el-dialog :before-close="handleClose" :visible.sync="orderCreateShow" width="70%">
      <el-form ref="form" :model="orderForm" label-width="80px">
        <el-form-item label="单据类型">
          <el-select v-model="orderForm.type" placeholder="请选择单据类型">
            <el-option v-for="item in typeList"
                       :label="item.name"
                       :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="单据名称">
          <el-input v-model="orderForm.name"></el-input>
        </el-form-item>
        <el-form-item label="附加信息">
          <el-input v-model="orderForm.abs"></el-input>
        </el-form-item>
        <el-form-item label="往来单位">
          <el-select v-model="orderForm.unit" placeholder="请选择往来单位">
            <el-option v-for="item in unitList"
                       :label="item.name"
                       :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="货品详情"
                      v-show="
                      orderForm.type != 7 &&
                      orderForm.type != 8 &&
                      orderForm.type != 9 &&
                      orderForm.type != 10 &&
                      orderForm.type != 11 &&
                      orderForm.type != 12 &&
                      orderForm.type != 13 &&
                      orderForm.type != 14">
          <el-row v-for="(item,index) in formOrderList">
            <el-select v-model="item.key" placeholder="请选择货品类型"
                       @change="item.dataList = getEntityData(item.key),item.id = null,item.num = 0,item.max = 0">
              <el-option v-for="item in KVlist"
                         :label="item"
                         :value="item"/>
            </el-select>
            <el-select v-model="item.id" placeholder="请输入产品型号"
                       @change="getMaxValue(item.id,index)"
                       filterable>
              <el-option v-for="item in item.dataList"
                         :label="item.name"
                         :value="item.id"/>
            </el-select>
            数量：
            <el-input-number v-model="item.num" @change="orderFormMoneyUpdate" :min="0"></el-input-number>
            单价：
            <el-input-number v-model="item.money" @change="orderFormMoneyUpdate" :precision="2" :step="0.1" :min="0"/>
            <span v-show="item.id">仓库库存：{{item.max ? item.max : 0}}件</span>
            <!--      收入项为售出，存在库存限制      -->
            <span v-show="inputList.indexOf(orderForm.type) !== -1 && item.num > item.max">，库存不足!</span>
          </el-row>
          <el-button @click="formOrderListAdd()">添加</el-button>
        </el-form-item>
        <el-form-item label="优惠金额"
                      v-show="
                      orderForm.type != 7 &&
                      orderForm.type != 8 &&
                      orderForm.type != 9 &&
                      orderForm.type != 10 &&
                      orderForm.type != 11 &&
                      orderForm.type != 12 &&
                      orderForm.type != 13 &&
                      orderForm.type != 14">
          <el-input-number v-model="orderForm.discount" @change="orderFormMoneyUpdate" :precision="2" :step="0.1" :min="0"/>
        </el-form-item>
        <el-form-item label="金额">
          <el-input v-model="orderForm.money"
                    :disabled="orderForm.type != 7 &&
                      orderForm.type != 8 &&
                      orderForm.type != 9 &&
                      orderForm.type != 10 &&
                      orderForm.type != 11 &&
                      orderForm.type != 12 &&
                      orderForm.type != 13 &&
                      orderForm.type != 14"></el-input>
        </el-form-item>
        <el-form-item label="交易账户">
          <el-select v-model="orderForm.account" placeholder="请选择交易账户">
            <el-option v-for="item in accountList"
                       :label="item.name"
                       :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="完成金额">
          <el-input v-model="orderForm.transactionAmount"></el-input>
        </el-form-item>
      </el-form>
      <el-button @click="orderCreateSave">保存</el-button>
    </el-dialog>

    <!--  单据编辑  -->
    <el-dialog :before-close="handleClose" :visible.sync="orderUpdateShow" width="70%">
      <el-form ref="form" :model="orderForm" label-width="80px">
        <el-form-item label="单据类型">
          <el-select v-model="orderForm.type" placeholder="请选择单据类型" disabled>
            <el-option v-for="item in typeList"
                       :label="item.name"
                       :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="单据名称">
          <el-input v-model="orderForm.name"></el-input>
        </el-form-item>
        <el-form-item label="单据编号">
          <el-input v-model="orderForm.code" disabled></el-input>
        </el-form-item>
        <el-form-item label="附加信息">
          <el-input v-model="orderForm.abs"></el-input>
        </el-form-item>
        <el-form-item label="往来单位">
          <el-select v-model="orderForm.unit" placeholder="请选择活动区域">
            <el-option v-for="item in unitList"
                       :label="item.name"
                       :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="货品详情"
                      v-loading="changeLoading"
                      v-show="
                      orderForm.type != 7 &&
                      orderForm.type != 8 &&
                      orderForm.type != 9 &&
                      orderForm.type != 10 &&
                      orderForm.type != 11 &&
                      orderForm.type != 12 &&
                      orderForm.type != 13 &&
                      orderForm.type != 14">
          <el-row v-for="(item,index) in formOrderList">
            <el-select v-model="item.key" placeholder="请选择货品类型"
                       @change="item.dataList = getEntityData(item.key),item.id = null,item.num = 0,item.max = 0">
              <el-option v-for="item in KVlist"
                         :label="item"
                         :value="item"/>
            </el-select>
            <el-select v-model="item.id" placeholder="请输入产品型号"
                       @change="getMaxValue(item.id,index)"
                       filterable>
              <el-option v-for="item in item.dataList"
                         :label="item.name"
                         :value="item.id"/>
            </el-select>
            数量：
            <el-input-number v-model="item.num" @change="orderFormMoneyUpdate" :min="0"></el-input-number>
            单价：
            <el-input-number v-model="item.money" @change="orderFormMoneyUpdate" :precision="2" :step="0.1" :min="0"/>
            <span v-show="item.id">仓库库存：{{item.max ? item.max : 0}}件</span>
            <!--      收入项为售出，存在库存限制      -->
            <span v-show="inputList.indexOf(orderForm.type) !== -1 && item.num > item.max">，库存不足!</span>
          </el-row>
          <el-button @click="formOrderListAdd()">添加</el-button>
        </el-form-item><el-form-item label="优惠金额"
                                     v-show="
                      orderForm.type != 7 &&
                      orderForm.type != 8 &&
                      orderForm.type != 9 &&
                      orderForm.type != 10 &&
                      orderForm.type != 11 &&
                      orderForm.type != 12 &&
                      orderForm.type != 13 &&
                      orderForm.type != 14">
        <el-input-number v-model="orderForm.discount" @change="orderFormMoneyUpdate" :precision="2" :step="0.1" :min="0"/>
      </el-form-item>
        <el-form-item label="金额">
          <el-input v-model="orderForm.money"
                    :disabled="orderForm.type != 7 &&
                      orderForm.type != 8 &&
                      orderForm.type != 9 &&
                      orderForm.type != 10 &&
                      orderForm.type != 11 &&
                      orderForm.type != 12 &&
                      orderForm.type != 13 &&
                      orderForm.type != 14"></el-input>
        </el-form-item>
        <el-form-item label="交易账户">
          <el-select v-model="orderForm.account" placeholder="请选择活动区域">
            <el-option v-for="item in accountList"
                       :label="item.name"
                       :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="完成金额">
          <el-input v-model="orderForm.transactionAmount"></el-input>
        </el-form-item>
      </el-form>
      <el-button @click="orderUpdateSave">修改</el-button>
      <el-button @click="orderUpdateSaveAndFainish" :disabled="changeLoading || !checkFormOrderList()">保存并过账</el-button>
    </el-dialog>

    <!--  交易账户编辑  -->
    <el-dialog :before-close="handleClose" :visible.sync="accountShow" width="70%">
      <el-row v-for="(item,index) in accountList">
        <el-col :span="6">
          <span  v-show="changeAccountIndex !== index">{{item.name}}</span>
          <el-input v-show="changeAccountIndex === index" v-model="changeAccount"/>
        </el-col>
        <el-col :span="4">
          <el-button v-if="changeAccountIndex !== index" @click="changeAccountIndex = index,changeAccount = item.name">编辑</el-button>
          <el-button v-if="changeAccountIndex === index" @click="accountChange(item)"
                     :disabled="changeAccount === null || changeAccount === '' || hasSameName(accountList,changeAccount)">保存</el-button>
          <el-button v-if="changeAccountIndex === index" @click="changeAccountIndex = '',changeAccount = ''">取消</el-button>
        </el-col>
<!--        <el-button @click="accountDel(item)">删除</el-button>-->
      </el-row>
      <el-row>
        <el-col :span="6" v-show="newAccount !== null">
          <el-input v-model="newAccount"/>
        </el-col>
        <el-col :span="4">
          <el-button v-if="newAccount === null"
                     @click="newAccount = ''"
                     >添加</el-button>
          <el-button v-if="newAccount !== null" @click="accountAdd()"
                     :disabled="newAccount === null || newAccount === '' || hasSameName(accountList,newAccount)">保存</el-button>
          <el-button v-if="newAccount !== null" @click="newAccount = null">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <!--  往来单位编辑  -->
    <el-dialog :before-close="handleClose" :visible.sync="unitShow" width="70%">
      <el-row v-for="(item,index) in unitList">
        <el-col :span="6">
          <span  v-show="changeUnitIndex !== index">{{item.name}}</span>
          <el-input v-show="changeUnitIndex === index" v-model="changeUnit"/>
        </el-col>
        <el-col :span="4">
          <el-button v-if="changeUnitIndex !== index" @click="changeUnitIndex = index,changeUnit = item.name">编辑</el-button>
          <el-button v-if="changeUnitIndex === index" @click="unitChange(item)"
                     :disabled="changeUnit === null || changeUnit === '' || hasSameName(unitList,changeUnit)">保存</el-button>
          <el-button v-if="changeUnitIndex === index" @click="changeUnitIndex = '',changeUnit = ''">取消</el-button>
        </el-col>
        <!--        <el-button @click="unitDel(item)">删除</el-button>-->
      </el-row>
      <el-row>
        <el-col :span="6" v-show="newUnit !== null">
          <el-input v-model="newUnit"/>
        </el-col>
        <el-col :span="4">
          <el-button v-if="newUnit === null"
                     @click="newUnit = ''"
          >添加</el-button>
          <el-button v-if="newUnit !== null" @click="unitAdd()"
                     :disabled="newUnit === null || newUnit === '' || hasSameName(unitList,newUnit)">保存</el-button>
          <el-button v-if="newUnit !== null" @click="newUnit = null">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!--  PDF  -->
    <el-dialog :before-close="handleClose"
               :fullscreen = "true"
               :visible.sync="fileShow"
               width="70%">
      <iframe
          v-if = "fileShow"
          id="mainIframe"
          :src="fileUrl"
          type="application/x-google-chrome-pdf"
          width="100%"
          height="600"
      />
    </el-dialog>
  </div>
</template>
<script>

import request from "@/utils/request";
import {DateChange} from "@/utils/public";
import {serverIp} from "../../public/config";
import {getMainMemory} from "@/utils/product";

export default {
  data() {
    return {
      orderCreateShow:false,
      orderForm:{},
      orderUpdateShow:false,

      search:"",//搜索关键词
      accountList:[],//交易账户列表
      unitList:[],//往来单位列表
      formOrderList:[],//订单产品列表
      entityList:[],//产品列表
      personList:[],//员工列表

      accountShow: false,//交易账户编辑
      newAccount:null,//交易账户编辑
      changeAccountIndex:"",
      changeAccount:"",
      unitShow: false,//往来单位编辑
      newUnit:null,//往来单位编辑
      changeUnit:"",
      changeUnitIndex:"",

      changeLoading:false,
      finishedSelect:"",
      finishedList:[
        {type:"1",name:"已过账"},
        {type:"0",name:"未过账"}
      ],
      typeSelect:[],
      accountSelect:[],
      unitSelect:[],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      timeSpase: [],
      timeSpase2: null,

      typeList:[
        {id:2,name:"进货单"},
        {id:3,name:"进货退货单"},
        {id:5,name:"销售单"},
        {id:6,name:"销售退货单"},
        {id:7,name:"一般费用单"},
        {id:8,name:"其他收入"},
        {id:9,name:"增加应收款"},
        {id:10,name:"增加应付款"},
        {id:11,name:"减少应收款"},
        {id:12,name:"减少应付款"},
        {id:13,name:"收款单"},
        {id:14,name:"付款单"}
      ],

      passType:"全部",
      passTypeList:[
        "已过账",
        "未过账",
        "全部",
      ],

      outputList : [2,6,7,10,11,14],//支出项
      inputList : [3,5,8,9,12,13],//收入项

      tableDate:[],
      currentPage4:1,
      size:10,
      total:0,
      KVlist:[],
      entityTotalList:[],
      inputMoneyToTal:0,
      inputMoneyFinish:0,
      inputMoney:0,
      fileUrl:"",
      fileShow:false,
      baseURL:"",
    }
  },
  mounted() {
    this.baseURL = serverIp.startsWith("localhost") ? `http://${serverIp}` : `https://${serverIp}`
    this.timeSpase = [(new Date()).getTime() - 3600 * 1000 * 24 * 30 , new Date().getTime() + 3600 * 1000 * 24]
    // this.timeSpase2 = [(new Date()).getTime() - 3600 * 1000 * 24 * 30 , new Date()]
    this.getKVlist()
    this.select()
    this.getAccountList()
    this.getUnitList()
    this.getPersonList()
    this.getEntityTotalList()
  },
  methods: {

    //获取KV列表
    getKVlist() {
      request.get("/cmdb-kv/AllKey").then(res => {
        this.KVlist = res.data
      }).then(() => {
        this.getEntityList()
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '获取KV失败'
        });
      }

    },
    //单据列表查询
    select(){
      request.get("/order/order-main/page?pageNum="+this.currentPage4+"&pageSize="+this.size+
          "&finished="+(this.finishedSelect ? this.finishedSelect : "")+
          "&type="+(this.typeSelect ? this.typeSelect : "")+
          "&timeSta="+(this.timeSpase ? new Date(this.timeSpase[0]).getTime() : 0)+
          "&timeEnd="+(this.timeSpase ? new Date(this.timeSpase[1]).getTime() : 0)+
          "&finSta="+(this.timeSpase2 ? new Date(this.timeSpase2[0]).getTime() : 0)+
          "&finEnd="+(this.timeSpase2 ? new Date(this.timeSpase2[1]).getTime() : 0)+
          "&account="+(this.accountSelect ? this.accountSelect : "")+
          "&unit="+(this.unitSelect ? this.unitSelect : "")+
          "&search="+(this.search ? this.search : "")
      ).then(res => {
        this.tableDate = res.data.records
        this.total = res.data.total
      })
    },
    //导出Excel
    exportExcel(){


      let url = "/order/order-main/exportExcel?pageNum="+this.currentPage4+"&pageSize="+this.size+
          "&finished="+(this.finishedSelect ? this.finishedSelect : "")+
          "&type="+(this.typeSelect ? this.typeSelect : "")+
          "&timeSta="+(this.timeSpase&&this.timeSpase[0] ? new Date(this.timeSpase[0]).getTime() : 0)+
          "&timeEnd="+(this.timeSpase&&this.timeSpase[1] ? new Date(this.timeSpase[1]).getTime() : 0)+
          "&finSta="+(this.timeSpase2&&this.timeSpase2.length===2&&this.timeSpase2[0] ? new Date(this.timeSpase2[0]).getTime() : 0)+
          "&finEnd="+(this.timeSpase2&&this.timeSpase2.length===2&&this.timeSpase2[1] ? new Date(this.timeSpase2[1]).getTime() : 0)+
          "&account="+(this.accountSelect ? this.accountSelect : "")+
          "&unit="+(this.unitSelect ? this.unitSelect : "")+
          "&search="+(this.search ? this.search : "")

      window.open(this.baseURL+url, '_blank')
      window.open(this.baseURL+url, '_blank')

      // request.get(url).then(res => {
      //   this.tableDate = res.data.records
      //   this.total = res.data.total
      // })
    },
    //导出Excel
    exportOrder(id){
      const backpath = this.baseURL;
      let timeIframe = setTimeout(this.GetIframeStatus, 10);
      let fileUrl = "";
      this.getBlob(backpath+'/order/order-main/exportOrder?orderId='+id).then(res => {
        let blob = new Blob([res], { type:"application/pdf" });
        this.fileUrl = URL.createObjectURL(blob);
        this.fileShow = true
      });
      // request.get("/order/order-main/exportOrder").then(res => {
      //   let name = res.data;
      //   this.getFile(name)
      // })
    },
    getBlob(url) {
      return new Promise(resolve => {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          }
        };
        xhr.send();
      });
    },
    //交易账户列表
    getAccountList() {
      request.get("/order/order-account/All").then(res => {
        this.accountList = res.data
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '获取交易账户列表失败'
        });
      }
    },
    //交易账户编辑
    accountChange(item){
      item.name = this.changeAccount
      request.post("/order/order-account/Save",item).then(res => {
        this.accountList = res.data
        this.changeAccountIndex = "";
        this.accountList = []
      }).finally(()=>{
        this.getAccountList()
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '交易账户修改失败'
        });
      }
    },
    //交易账户删除
    accountDel(item){

    },
    //交易账户新增
    accountAdd(){
      let item = {
        name:this.newAccount
      }
      request.post("/order/order-account/Save",item).then(res => {
        this.accountList = res.data
        this.newAccount = null;
        this.accountList = []
      }).finally(()=>{
        this.getAccountList()
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '交易账户添加失败'
        });
      }
    },
    //判断list中是否包含name相同的数据
    hasSameName(list,name){
      for (let i = 0 ; i < list.length ;i++){
        let item = list[i]
        if (item.name === name){
          return true;
        }
      }
      return false
    },
    //根据id查交易账户名
    getAccountName(id){
      let _item = this.accountList.find((item)=>{return item.id === id})
      return _item === null ? "" : _item.name
    },
    //判断库存
    checkFormOrderList(){

      if (this.inputList.indexOf(this.orderForm.type) === -1){
        return true
      }

      for (const item of this.formOrderList) {
        if (item.max<item.num){
          return false
        }
      }
      return true
    },
    //往来单位列表
    getUnitList() {
      request.get("/order/order-unit/All").then(res => {
        this.unitList = res.data
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '获取往来单位列表失败'
        });
      }
    },
    //往来单位编辑
    unitChange(item){
      item.name = this.changeUnit
      request.post("/order/order-unit/Save",item).then(res => {
        this.unitList = res.data
        this.changeUnitIndex = "";
        this.unitList = []
      }).finally(()=>{
        this.getUnitList()
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '交易账户修改失败'
        });
      }
    },
    //往来单位删除
    unitDel(item){

    },
    //往来单位新增
    unitAdd(){
      let item = {
        name:this.newUnit
      }
      request.post("/order/order-unit/Save",item).then(res => {
        this.unitList = res.data
        this.newUnit = null;
        this.unitList = []
      }).finally(()=>{
        this.getUnitList()
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '交易账户添加失败'
        });
      }
    },
    //根据id查往来单位名
    getUnitName(id){
      let _item = this.unitList.find((item)=>{return item.id === id})
      return _item === null ? "" : _item.name
    },
    //单据类型
    getTypeName(id){
      try{
        let _item = this.typeList.find((item)=>{return item.id === id})
        return _item === null ? "" : _item.name
      } catch (e) {
        return ""
      }
    },
    //产品列表
    getEntityList() {
      this.KVlist.forEach(item => {
        request.post("/CMDB/getPropertyChildren",item).then(res => {
          this.entityList.push({key:item,value:res.data})
        })
      })
    },
    async getEntityTotalList(){
      let res = await getMainMemory("entitySelectMemory");
      for (let i = 0; i < res.length; i++){
        const item = res[i];
        this.entityTotalList = this.entityTotalList.concat(item.treeChildren.map(_c=>{
          return {id:_c.id,name:_c.name}
        }))
      }
      // await request.post("/CMDB/entitySelect").then(res => {
      //   for (let i = 0; i < res.data.length; i++){
      //     const item = res.data[i];
      //     this.entityTotalList = this.entityTotalList.concat(item.treeChildren.map(_c=>{
      //       return {id:_c.id,name:_c.name}
      //     }))
      //   }
      // })
    },
    getEntityNameById(id) {
      let _item = this.entityTotalList.find((item)=>{return item.id == id})
      return (_item === null || _item === undefined) ? "" : _item.name
    },
    //产品列表
    getEntityData(key){
      let _value = this.entityList.find(item => item.key === key)
      if (_value === null){
        return []
      } else {
        return _value.value
      }
    },
    //人员列表
    getPersonList() {
      request.post("/getPersonlist",).then(res => {
        this.personList = res.data
      })
    },
    //人员id取姓名
    getPersonName(id){
      let _item = this.personList.find((item)=>{return item.id === id})
      return (_item === null || _item === undefined) ? "" : _item.username
    },
    //订单编辑
    OrderUpdate(row) {
      let _row = row
      this.orderForm = _row
      this.orderForm.type = parseInt(_row.type)
      this.getFormOrderList(_row.orderList)
      let _abs = _row.abs
      if (_abs.includes("【优惠金额：】")){
        this.orderForm.discount = _abs.split("【优惠金额：】")[1]
        this.orderForm.abs = _abs.split("【优惠金额：】")[0]
      }
      this.orderUpdateShow = true;
    },
    //红冲
    OrderReturn(row) {
      request.post("/order/order-main/Return",row).then(res => {
        if (res.code == "200"){
          this.$notify({
            title: '成功',
            message: '创建成功',
            type: 'success',
          });
          this.select();
          this.orderCreateShow = false
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    //订单产品列表
    async getFormOrderList(orderList) {
      this.changeLoading = true;
      let l = orderList.split(",");
      let orders = []
      for (let i = 0; i < l.length; i++) {
        let _o = l[i].split(":")
        if (_o.length===4){
          let max = await this.getMaxValueById(parseInt(_o[1]));
          let dataList = await this.getEntityData(_o[0])
          orders.push({key:_o[0],id:parseInt(_o[1]),num:parseInt(_o[2]),money:_o[3],max:max,dataList:dataList})
        }
      }
      this.$nextTick(()=>{
        this.formOrderList=orders
        this.changeLoading = false;
      })
    },
    orderFormMoneyUpdate(){
      try {
        if (this.orderForm.discount === undefined ||
            this.orderForm.discount === null ||
            this.orderForm.discount === ""
        ){
          this.orderForm.discount = 0
        }
        let _money = 0
        this.formOrderList.forEach(item=>{
          _money += item.money * item.num
        })
        _money -= this.orderForm.discount
        this.orderForm.money = _money.toFixed(2);
      } catch (e) {
        this.orderForm.money = 0
      }
    },
    //新建订单保存
    async orderCreateSave() {
      let orderForm = this.orderForm
      if(!orderForm
          || orderForm.type === undefined || orderForm.type === null || orderForm.type === ""
          || orderForm.name === undefined || orderForm.name === null || orderForm.name === ""
          || orderForm.unit === undefined || orderForm.unit === null || orderForm.unit === ""
          || orderForm.account === undefined || orderForm.account === null || orderForm.account === ""){
        this.$notify.error({
          title: '错误',
          message: "请填写"+
              (orderForm.type === undefined || orderForm.type === null || orderForm.type === "" ? "单据类型," : "") +
              (orderForm.name === undefined || orderForm.name === null || orderForm.name === "" ? "单据名称," : "") +
              (orderForm.unit === undefined || orderForm.unit === null || orderForm.unit === "" ? "往来单位," : "") +
              (orderForm.account === undefined || orderForm.account === null || orderForm.account === "" ? "交易账户," : "")
        });
        return
      }
      if(orderForm.money === undefined || orderForm.money === null || orderForm.money === ""){
        this.orderForm.money = 0
      }
      let _ol =this.formOrderList.filter(item => item.id && item.id !== 0).map(item => {
        return item.key + ":" + item.id + ":" + item.num + ":" + item.money
      }).join(",")
      let _orderForm = this.orderForm
      if (_orderForm.discount !== undefined && _orderForm.discount !== null) {
        _orderForm.abs += "【优惠金额：】" + _orderForm.discount
      }
      _orderForm.orderList = _ol
      request.post("/order/order-main/Save",_orderForm).then(res => {
        if (res.code == "200"){
          this.$notify({
            title: '成功',
            message: '创建成功',
            type: 'success',
          });
          this.select();
          this.orderCreateShow = false
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },

    //编辑订单
    orderUpdateSave() {
      let orderForm = this.orderForm

      if(!orderForm
          || orderForm.type === undefined || orderForm.type === null || orderForm.type === ""
          || orderForm.name === undefined || orderForm.name === null || orderForm.name === ""
          || orderForm.unit === undefined || orderForm.unit === null || orderForm.unit === ""
          || orderForm.account === undefined || orderForm.account === null || orderForm.account === ""){
        this.$notify.error({
          title: '错误',
          message: "请填写"+
              (orderForm.type === undefined || orderForm.type === null || orderForm.type === "" ? "单据类型," : "") +
              (orderForm.name === undefined || orderForm.name === null || orderForm.name === "" ? "单据名称," : "") +
              (orderForm.unit === undefined || orderForm.unit === null || orderForm.unit === "" ? "往来单位," : "") +
              (orderForm.account === undefined || orderForm.account === null || orderForm.account === "" ? "交易账户," : "")
        });
        return
      }
      if(orderForm.money === undefined || orderForm.money === null || orderForm.money === ""){
        this.orderForm.money = 0
      }
      let _ol =this.formOrderList.filter(item => item.id && item.id !== 0).map(item => {
        return item.key + ":" + item.id + ":" + item.num + ":" + item.money
      }).join(",")
      let _orderForm = this.orderForm
      if (_orderForm.discount !== undefined && _orderForm.discount !== null) {
        _orderForm.abs += "【优惠金额：】" + _orderForm.discount
      }
      _orderForm.orderList = _ol
      request.post("/order/order-main/Save",_orderForm).then(res => {
        if (res.code == "200"){
          this.$notify({
            title: '成功',
            message: '创建成功',
            type: 'success',
          });
          this.select();
          this.orderUpdateShow = false
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    //保存并过账
    orderUpdateSaveAndFainish(){
      let _ol =this.formOrderList.filter(item => item.id && item.id !== 0).map(item => {
        return item.key + ":" + item.id + ":" + item.num + ":" + item.money
      }).join(",")
      let _orderForm = this.orderForm
      _orderForm.orderList = _ol
      _orderForm.finished = "1"
      request.post("/order/order-main/Save",_orderForm).then(res => {
        if (res.code == "200"){
          this.$notify({
            title: '成功',
            message: '创建成功',
            type: 'success',
          });
          this.select();
          this.orderUpdateShow = false
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    //添加货品
    formOrderListAdd() {
      this.formOrderList.push({});
    },
    //获取货品最大库存
    async getMaxValue(id,index){
      let max = -1;
      if(id !== null && id !== undefined){
        await request.post("/order/order-main/getEntityMaxValue",id).then(res => {
          if (res.code == "200"){
            max = res.data
          }
        })
        this.formOrderList[index].max = max
        await this.$forceUpdate();
        // return max
      }
    },
    async getMaxValueById(id){
      let max = -1;
      if(id !== null && id !== undefined){
        await request.post("/order/order-main/getEntityMaxValue",id).then(res => {
          if (res.code == "200"){
            max = res.data
          }
        })
        return max
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            if (this.orderForm.discount !== undefined && this.orderForm.discount !== null) {
              this.orderForm.abs += "【优惠金额：】" + this.orderForm.discount
            }
            done();
          })
          .catch(_ => {
          });
    },
    handleSizeChange(val) {
      this.size = val;
      this.select();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      this.select();
    },
    DateChange (time){
      return DateChange(new Date(time),"yyyy-MM-dd EEE HH:mm:ss");
    },
    getSummaries(param) {
      const cols = ["transactionAmount","money"]
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '本页非红冲合计';
          return;
        }
        const values = data.map(item => {
          //   if(this.inputList.indexOf(item[column.type]) !== -1){
          //     return Number(item[column.property])
          //   } else if (this.outputList.indexOf(item[column.type]) !== -1){
          //     return -(Number(item[column.property]))
          //   } else {
          //     return 0
          //   }
          try{
            if (item.ret == "1"){
              return 0
            } else if (this.outputList.indexOf(item.type) !== -1){
              return -Number(item[column.property])
            } else if (this.inputList.indexOf(item.type) !== -1){
              return Number(item[column.property])
            }
          } catch (e) {
            return Number(item[column.property])
          }
        });
        if (cols.indexOf(column.property) !== -1) {
          let _sum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0)
          if (column.property === "money"){
            this.inputMoneyToTal = _sum
          } else if (column.property === "transactionAmount"){
            this.inputMoneyFinish = _sum
          }
          sums[index] = _sum + "元";
          sums[index];
        }
      });
      this.inputMoney = this.inputMoneyToTal - this.inputMoneyFinish
      return sums
    },
    changRed({row}){
      if (row.ret == "1") { // 变颜色的条件
        return {
          color: "red"  // 这个return的就是样式 可以是color 也可以是backgroundColor
        }
      }
    }
  }
}
</script>
<style scoped>

</style>